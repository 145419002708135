<template>
  <mt-popup
    class="badgeExample"
    v-model="popupVisible"
  >
    <div class="box">
      <div class="close" @click="popupVisible = false">
        <img src="../assets/close.png">
      </div>
      <div class="comtent">
        <div class="title">{{ $t('example') }}</div>
        <div class="val">
          <img src="../assets/badgeExample.png">
          <div>{{ $t('badgeExampleTips') }}</div>
        </div>
      </div>
    </div>
  </mt-popup>
</template>

<script>
export default {
  name: 'BadgeExample',
  data () {
    return {
      popupVisible: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.badgeExample {
  border-radius: 10px;
  background-color: transparent;
  .box {
    width: 100vw;
    height: 90vh;
    .close {
      height: 50px;
      img {
        position: absolute;
        width: 30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .comtent {
      overflow: auto;
      position: absolute;
      top: 50px;
      left: 10px;
      right: 10px;
      bottom: 0px;
      background-color: white;
      border-radius: 10px;
      padding-bottom: 10px;
      .title {
        color: #252525;
        font-size: 17px;
        padding: 15px 10px;
        text-align: center;
      }
      .val {
        text-align: center;
        font-size: 12px;
        color: #6486FF;
        img {
          width: 223px;
          margin: 4px 10px 10px 10px;
        }
      }
    }
  }
}
</style>