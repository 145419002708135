<template>
  <div class="paymentMethod">
    <mt-header :title="$t('paymentMethod')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back1.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <step-item :step="7" />
    <div class="content">
      <div class="item">
        <div class="title">{{ $t('bank') }}</div>
        <mt-field
            :placeholder="$t('bankPlaceholder')"
            :state="bankCodeState"
            readonly
            v-model="(Enum.bank.filter(e => e.code === form.bankCode)[0]||{}).name"
            @click.native="onPopupPicker(Enum.bank, (e) => {
              form.bankCode = e.code;
              BEHAVIOR_ADD({
                id: `P07_C02_S_BANKCODE`,
                newValue: form.bankCode
              })
            })"
        >
          <mt-spinner v-if="Enum.bankLoading" type="snake" color="#4F9B41" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('cardNoType') }}</div>
        <mt-field
            :placeholder="$t('cardNoTypePlaceholder')"
            :state="cardNoTypeState"
            readonly
            v-model="(Enum.cardType.filter(e => e.code === form.cardNoType)[0]||{}).name"
            @click.native="onPopupPicker(Enum.cardType, (e) => {
              form.cardNoType = e.code;
              BEHAVIOR_ADD({
                id: `P07_C02_S_CARDNOTYPE`,
                newValue: form.cardNoType
              })
            })"
        >
          <mt-spinner v-if="Enum.cardTypeLoading" type="snake" color="#4F9B41" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('bankAccountNo') }}</div>
        <mt-field
            :placeholder="$t('bankAccountNoPlaceholder')"
            :state="bankCardNoState"
            v-model="form.bankCardNo"
            v-mask="'##############################'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P07_C03_I_BANKCARDNO',
              newValue: form.bankCardNo
            })"
        ></mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('bankAccountHolder') }}</div>
        <mt-field
            disabled
            :placeholder="$t('bankAccountHolderPlaceholder')"
            :state="bankAccountNameState"
            v-model="form.bankAccountName"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P07_C01_I_BANKACCOUNTNAME',
              newValue: form.bankAccountName
            })"
        ></mt-field>
      </div>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
    <badge-example ref="badgeExample" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { applyStep } from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
import stepItem from '../../components/stepItem.vue'
import popupPicker from '@/components/popupPicker.vue'
import badgeExample from '@/components/badgeExample.vue'
import {nameRules, emptyRules, phoneRules, bankCardRules} from '../../utils/rules'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import EXIF from 'exif-js'
import mixin from './mixin'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: 'PaymentMethod',
  mixins: [mixin],
  components: {
    callPhone,
    stepItem,
    popupPicker,
    badgeExample
  },
  data () {
    return {
      isCheck: false,
      form: {
        bankAccountName: '', // 银行账户持有人
        bankCode: '', // 银行名称
        cardNoType: '',
        bankCardNo: '', // 银行账号
      }
    }
  },
  computed: {
    ...mapState(["apply", "user"]),
    bankAccountNameState() {
      this.onStorageItem('bankAccountName')
      return this.isCheck ? nameRules(this.form.bankAccountName) : null;
    },
    bankCodeState() {
      this.onStorageItem('bankCode')
      return this.isCheck ? emptyRules(this.form.bankCode) : null;
    },
    cardNoTypeState() {
      this.onStorageItem('cardNoType')
      return this.isCheck ? emptyRules(this.form. cardNoType) : null;
    },
    bankCardNoState() {
      this.onStorageItem('bankCardNo')
      return this.isCheck ? bankCardRules(this.form.bankCardNo,this.form.cardNoType) : null;
    },
    imageOfCompanyIdState() {
      this.onStorageItem('imageOfCompanyId')
      return this.isCheck ? emptyRules(this.form.imageId) : null;
    },
    payOrgState() {
      this.onStorageItem('payOrg')
      return this.isCheck ? emptyRules(this.form.payOrg) : null;
    },
    payFeeState() {
      this.onStorageItem('payFee')
      return this.isCheck ? emptyRules(this.form.payFee) : null;
    },

    payChannelState() {
      this.onStorageItem('payChannel')
      return this.isCheck ? emptyRules(this.form.payChannel) : null;
    },
    payPhoneState() {
      this.onStorageItem('payPhone')
      return this.isCheck ? phoneRules(this.form.payPhone) : null;
    },
  },
  watch: {
    'form.payType': {
      handler() {
        this.onStorageItem('payType')
      }
    },
    'form.payOrg': {
      handler() {
        try {
          this.form.payFee = (this.Enum.institution.filter(e => e.code == this.form.payOrg)[0]||{}).attr2
        } catch(e) {
          this.form.payFee = ''
        }
      }
    },
    'Enum.institutionLoading': {
      handler() {
        try {
          this.form.payFee = (this.Enum.institution.filter(e => e.code == this.form.payOrg)[0]||{}).attr2
        } catch(e) {
          this.form.payFee = ''
        }
      }
    }
  },
  created() {
    gtmPageView(this)
    Object.keys(this.form).forEach((val) => {
      this.form[val] = this.apply[val] || ''
    })
    if (!this.form.payType) {
      this.form.payType = 'BANK'
    }
    let name = [
      this.apply.firstName,
      this.apply.middleName,
      this.apply.lastName,
    ].filter(e => e).join(' ')
    this.form.bankAccountName = this.user.name || name || ''
    try {
      this.form.payFee = (this.Enum.institution.filter(e => e.code == this.form.payOrg)[0]||{}).attr2
    } catch(e) {
      this.form.payFee = ''
    }
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P07_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P07_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_BANK(); // 获取银行
      this.GET_CARD_TYPE();
      this.GET_INSTITUTION(); // 获取机构
      this.GET_PAY_CHL(); // 获取通道
    },
    // 上传图像
    onUploadCompanyFront(id, file) {
      this.form.imageId = id
      this.BEHAVIOR_ADD({
        id: `P07_C05_I_ID`,
        newValue: id
      })
      let self = this
      EXIF.getData(file.origin, function() {
        let data = EXIF.getAllTags(this)
        if (data && data.MakerNote && data.MakerNote.length > 100) {
          data.MakerNote = data.MakerNote.slice(0, 100)
        }
        self.BEHAVIOR_ADD({
          id: `P07_C05_E_EXIF`,
          newValue: JSON.stringify(data)
        })
      })
    },
    // 校验输入内容
    validation(list) {
      if (list.indexOf('bankAccountName') >= 0 && this.bankAccountNameState != "success") {
        return this.$toast(this.bankAccountNameState == "warning"
          ? this.$t("bankAccountHolderPlaceholder")
          : this.$t("bankAccountHolderError"));
      }
      if (list.indexOf('bankCode') >= 0 && this.bankCodeState != "success") {
        return this.$toast(this.bankCodeState == "warning"
          ? this.$t("bankPlaceholder")
          : this.$t("bankError"));
      }
      if (list.indexOf('bankCardNo') >= 0 && this.bankCardNoState != "success") {
        return this.$toast(this.bankCardNoState == "warning"
          ? this.$t("bankAccountNoPlaceholder")
          : this.$t("bankAccountNoError"));
      }
      if (list.indexOf('payChannel') >= 0 && this.payChannelState != "success") {
        return this.$toast(this.payChannelState == "warning"
          ? this.$t("channelPlaceholder")
          : this.$t("channelError"));
      }
      if (list.indexOf('imageOfCompanyId') >= 0 && this.imageOfCompanyIdState != "success") {
        return this.$t("imageOfCompanyIdError");
      }
      if (list.indexOf('payOrg') >= 0 && this.payOrgState != "success") {
        return this.$toast(this.payOrgState == "warning"
          ? this.$t("institutionNamePlaceholder")
          : this.$t("institutionNameError"));
      }
      if (list.indexOf('payFee') >= 0 && this.payFeeState != "success") {
        return this.$toast(this.payFeeState == "warning"
          ? this.$t("disbursementHandlingFeePlaceholder")
          : this.$t("disbursementHandlingFeeError"));
      }
      if (list.indexOf('payPhone') >= 0 && this.payPhoneState != "success") {
        return this.$toast(this.payPhoneState == "warning"
          ? this.$t("phoneNumberPlaceholder")
          : this.$t("phoneNumberError"));
      }
    },
    async submit() {
      this.isCheck = true;
      var error = this.validation(['bankAccountName', 'bankCode', 'bankCardNo','cardNoType']);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('08_PAYMENT_METHOD', { content_name: '08_PAYMENT_METHOD' })
        }
        // Google Analytics  PAYMENT_METHOD
        if (localStorage['ga']) {
          window.gtag('event', 'PAYMENT_METHOD')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        currentStep: 7,
        totalSteps: 8,

        bankName: (this.Enum.bank.filter(e => e.code === this.form.bankCode)[0]||{}).name,

        applyId: this.user.applyId,
        ...this.form
      }
      await this.$axios({
        method: "post",
        url: applyStep,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code === "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            await this.$router.push('/step/confirm')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()
      branch.logEvent('08_PAYMENT_METHOD', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.paymentMethod {
  background-color: #FBFBFF;
  min-height: 100vh;
  .content {
    padding: 10px;
    .navbar {
      display: flex;
      border-radius: 45px;
      background-color: white;
      .item {
        flex: 1;
        text-align: center;
        height: 45px;
        color: #939393;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 45px;
        &.focus {
          background-color: #4F9B41;
          font-weight: bold;
          color: white;
        }
      }
    }
    .item {
      margin-top: 20px;
      .mint-cell {
        background: white;
        /deep/ .mint-cell-wrapper {
          background: none;
        }
      }
      .prompt {
        position: relative;
        font-size: 16px;
        color: #1D1D1D;
        margin: 15px 6px 40px 6px;
        .val {
          &::after {
            content: '*';
            font-size: 14px;
            vertical-align: top;
            color: #E92121;
          }
        }
        .example {
          position: absolute;
          right: 0px;
          top: 0px;
          text-decoration: underline;
          font-weight: 500;
          font-size: 16px;
          color: #239DE8;
        }
      }
      .image {
        margin: 0 100px;
      }
      .tip {
        font-size: 12px;
        color: #ADB0B6;
        font-size: 15px;
        text-align: center;
      }
      .title {
        margin: 6px 15px;
        font-size: 16px;
        color: #1D1D1D;
        &::after {
          content: '*';
          font-size: 14px;
          vertical-align: top;
          color: #E92121;
        }
      }
      .tips {
        font-size: 13px;
        color: #239DE8;
        margin: 0px 15px 5px 15px;
      }
      .mint-field {
        border-radius: 6px;
        min-height: 46px;
      }
    }
  }
  .error {
    font-size: 12px;
    color: red;
    font-size: 14px;
    text-align: center;
  }
  .iconfont {
    margin: 0 5px;
    color: #4F9B41;
  }
  .bottom {
    padding: 40px 10px;
    .mint-button {
      border-radius: 6px;
    }
  }
}
</style>